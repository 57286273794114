import './App.css';
import React, {useState} from "react";
import {GrGroup, GrOptimize, GrScheduleNew, GrSchedules, GrBusinessService} from "react-icons/gr";
import {ImExit, ImSearch, ImClipboard, ImStatsDots, ImUsers, ImFileEmpty, ImFilesEmpty, ImList,ImCog} from "react-icons/im";
import {GoSearch} from "react-icons/go";


function App() {
    const [leftPanel, setLeftPanel] = useState(true);
    const [searchField, setSearchField] = useState(false);


    return (
        <div className="App">
            <div className="main">
                <div className={leftPanel
                    ? "left-panel"
                    : "left-panel move"
                }>
                    <div className="left__panel-items">
                        <div className="owner-block">
                            <div className="ownerImg"/>
                            <div className="ownerName">
                                Ип СуперИП
                            </div>
                            <ImCog className='stngs'/>
                        </div>
                        <ul className="link-block">
                            {/*<li><ImFileEmpty className="ico"/><span>Новый ордер</span></li>*/}
                            <li><ImFilesEmpty className="ico"/><span>Ордера</span></li>
                            <li><ImUsers className="ico"/><span>Клиенты</span></li>
                            <li><ImStatsDots className="ico"/><span>Статистика</span></li>
                            <li><ImList className="ico"/><span>Инвентарь</span></li>
                        </ul>
                        <div className="someField">
                            <div className="someFieldItems">

                            </div>
                        </div>
                        <div className="addOrder">
                            <div className="plus"/>
                            <span>Новый ордер</span>

                        </div>
                        <div className="exit"><ImExit/> <span>Выход</span></div>
                    </div>
                </div>


                <div className={leftPanel
                    ? "body move"
                    : "body"
                }>
                    <div className="body-field">
                        <div className="body__top-items">
                            <div className="menu-button"
                                 onClick={() => setLeftPanel(!leftPanel)}>
                                <div className={leftPanel
                                    ? "burger-line  move"
                                    : "burger-line"
                                }/>
                            </div>
                            {/*<span>Прокатопрограмма</span>*/}
                            <div className="top-input-field">
                                <input type="text" className={searchField
                                    ? 'top-input opn'
                                    : 'top-input'
                                }
                                    // onClick={() => setSearchField(!searchField)}
                                />
                                <GoSearch className="si" onClick={() => setSearchField(!searchField)}/>
                            </div>
                        </div>
                        <div className="body-items">
                            <div className={leftPanel
                                     ? "body__items-main move"
                                     : "body__items-main"
                                 }>
                                <div className="item ps">
                                    <div className="number">
                                        №
                                    </div>
                                    <div className="itemName">
                                        Наименование
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        Возврат
                                    </div>
                                    {/*<div className="itemActive" title={'активный'}/>*/}

                                </div>
                                <div className="item">
                                    <div className="number">
                                        1
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        2
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        3
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        4
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'активный'}/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        5
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        6
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        7
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'активный'}/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        8
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        9
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        10
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'активный'}/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        11
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        12
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        13
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'активный'}/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        14
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        15
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        13
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'активный'}/>

                                </div>
                                <div className="item">
                                    <div className="number">
                                        14
                                    </div>
                                    <div className="itemName">
                                        Столик раскладной детский
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive" title={'закрыт'}/>
                                </div>
                                <div className="item">
                                    <div className="number">
                                        15
                                    </div>
                                    <div className="itemName">
                                        Коньки детские с 4мя колёсами на шнурках
                                    </div>
                                    {/*<div className="itemSDate">*/}
                                    {/*    с 16.01.2022*/}
                                    {/*</div>*/}
                                    <div className="itemEDate">
                                        16.01.2022
                                    </div>
                                    <div className="itemActive"/>

                                </div>

                            </div>
                                <div className="body__items-rightBar">
                                <h2>rBlock</h2>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default App;
